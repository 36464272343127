import "./App.css";
import "react-tooltip/dist/react-tooltip.css";

import Gravatar from "react-gravatar";
import { FaLinkedin } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { AiOutlineGithub, AiOutlineMail } from "react-icons/ai";
import { RiSeparator } from "react-icons/ri";
import { TbApi } from "react-icons/tb";
import { SiCodeium } from "react-icons/si";
import { Tooltip } from "react-tooltip";

function App() {
  return (
    <div className="App">
      <Tooltip id="my-tooltip" />

      <div className="cover"></div>
      <Gravatar
        className="App-logo"
        size={170}
        email="joaomaciel.n@gmail.com"
      />
      <div className="title-content">
        <strong className="title">Jon Maciel Neves Sampaio</strong>
        <p className="sub-title">Senior Full-Stack Software Engineer</p>
        <p className="sub-title">
          Ruby on Rails - Elixir - GraphQL - ReactJS - Apollo
        </p>

        <p className="sub-title">
          <AiOutlineMail />
          <a
            className="App-email"
            href="mailto:jon@maciel.dev"
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Click to send me an email"
          >
            jon@maciel.dev
          </a>
        </p>
      </div>
      <div className="App-social">
        <a
          className="App-social-link"
          href="https://www.linkedin.com/in/jonmaciel"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Linkedin profile"
        >
          <FaLinkedin />
        </a>
        <a
          className="App-social-link"
          href="https://github.com/jonmaciel"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Github profile"
        >
          <AiOutlineGithub />
        </a>
        <a
          className="App-social-link"
          href="https://www.instagram.com/jon_maciel/"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Instagram profile"
        >
          <FiInstagram />
        </a>

        <a
          className="App-social-link"
          href="https://codeium.com/profile/jon_maciel"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Codeium profile"
        >
          <SiCodeium />
        </a>
      </div>
      <RiSeparator />
      <div className="projects">
        <strong className="title">Side Projects</strong>
        <a
          className="App-link"
          href="https://dindim.digital"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Mobile App to help parents to teach children financial education."
        >
          Dindim.Digital
        </a>
        <a
          className="App-link"
          href="https://mathwarrior.app"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Web App to help kids on math leaning."
        >
          Math Warrior
        </a>
        <a
          className="App-link"
          href="https://github.com/jonmaciel/smart_promo_api"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="API I created to be used as GraphQL sample."
        >
          <TbApi /> RoR + GraphQL Project Sample
        </a>
        <a
          className="App-link"
          href="https://play.google.com/store/apps/details?id=com.unionassets.android.stretchy.slime"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Android game I developed using Unity."
        >
          Stretchy Slime
        </a>
      </div>
    </div>
  );
}

export default App;
